import './rte.styles.scss'

const RTE = () => {
    return (
        <div className='container-rte'>
            <h1>Documentación</h1>

            <a href="/" >
            <i>Regresar a página principal ‹‹‹ </i>
            </a>
            <br/ >
            <br/ >
            <a href="/rte/Acta autorizacion representante legal para la solicitud de RTE (2).pdf" download>
                <i>Acta autorización representante legal para la solicitud de RTE ↗</i>
            </a>
            <br/ >
            <a href="/rte/certificacion Antecedente judiciales (2).pdf" download>
                <i>Certificación Antecedentes judiciales ↗</i>
            </a>
            <br/ >
            <a href="/rte/certificacion honorarios (2).pdf" download>
                <i>Certificación honorarios ↗</i>
            </a>
            <br/ >
            <a href="/rte/certificado de existencia y representacion legal (2).pdf" download>
                <i>Certificado de existencia y representación legal ↗</i>
            </a>
            <br/ >
            <a href="rte/Documento de Constitucion Fundacion Almargen (2).pdf" download>
                <i>Documento de Constitución Fundación Almargen ↗</i>
            </a>
            <br/ >
            <a href="rte/Estados Financieros 2024 Fundación Almargen.pdf" download>
                <i>Estados Financieros 2024 ↗</i>
            </a>
            <br/ >
            <a href="rte/Solicitud Regimen Tributario Especial.pdf" download>
                <i>Solicitud Régimen Tributario Especial ↗</i>
            </a>
            <br/ >
            <a href="rte/Estatutos y Acta.pdf" download>
                <i>Estatutos y Acta FUNDACIÓN ALMARGEN ↗</i>
            </a>
            <br/ >
        </div>
    );
}
    
export default RTE;
            